define("discourse/plugins/vex-discourse-oauth2/discourse/initializers/vex-sso", ["exports", "discourse/lib/plugin-api", "discourse/lib/logout"], function (_exports, _pluginApi, _logout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const REDIRECT_URL = "https://pd.vex.com";
  function myLogout(currentUser) {
    let retryCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let maxRetries = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 3;
    if (currentUser) {
      currentUser.destroySession().then(() => {
        console.log("Session destroyed, redirecting...");
        (0, _logout.default)({
          redirect: REDIRECT_URL
        });
      }).catch(err => {
        console.warn("Logout attempt failed", err);
        if (retryCount < maxRetries) {
          setTimeout(() => myLogout(currentUser, retryCount + 1), 1000); // Retry with a 1-second delay
        } else {
          console.error("Logout failed after multiple attempts.");
        }
      });
    }
  }
  var _default = _exports.default = {
    name: "vex-sso",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.11.7", api => {
        $(document).on('discourse:logout', function () {
          console.log('Logout event triggered');
        });
        const currentUser = api.getCurrentUser();
        if (currentUser) {
          console.log("Checking user groups for access control");
          const groups = currentUser.groups.map(group => group.name);
          const isAdmin = currentUser.admin;
          const isStaff = currentUser.staff;
          const hasVex = groups.some(group => group.startsWith("vex"));
          console.log(`User details: admin: ${isAdmin}, staff: ${isStaff}, vex access: ${hasVex}`);
          const hasAccess = isAdmin || isStaff || hasVex;
          if (!hasAccess) {
            console.warn("User lacks access. Logging out and redirecting.");
            myLogout(currentUser);
          }
        } else {
          console.warn("No user is currently logged in.");
        }
        // window.dapi = api
      });
    }
  };
});